:host ::ng-deep tr-svg svg {
  fill: #888888;
  cursor: pointer;
}

:host ::ng-deep tr-svg svg:hover {
  fill: #046e78;
}

:host ::ng-deep .active svg {
  fill: #046e78 !important;
}

.tr-overflow-hidden{
  overflow-y: hidden;
}

/* Common classes for switch slider */
.tr-switch-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.tr-switch-btn input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tr-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e8e8e8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tr-switch-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  top: 2px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .tr-switch-slider {
  background-color: #02b3c6;
}

input:focus + .tr-switch-slider {
  box-shadow: 0 0 1px #02b3c6;
}

input:checked + .tr-switch-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.tr-switch-slider.round {
  border-radius: 12px;
}

.tr-switch-slider.round:before {
  border-radius: 50%;
}

.tr-capitalize-first-letter {
  text-transform: capitalize;
}

.tr-truncate-text {
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tr-display-none {
  display: none;
}

.trBackgroundColor {
  background: #f9f9f9;
}

.tr-dashed {
  border: none;
  border-top: 1px dashed #000;
}

.top5 {
  margin-top: 5px;
}

.top7 {
  margin-top: 7px;
}

.top10 {
  margin-top: 10px;
}

.top15 {
  margin-top: 15px;
}

.top16 {
  margin-top: 16px;
}

.top17 {
  margin-top: 17px;
}

.top20 {
  margin-top: 20px;
}

.top25 {
  margin-top: 25px;
}

.top30 {
  margin-top: 30px;
}

.top35 {
  margin-top: 35px;
}

.top40 {
  margin-top: 40px;
}

.top50 {
  margin-top: 50px;
}

.top100 {
  margin-top: 100px;
}

.bottom5 {
  margin-bottom: 5px;
}

.bottom7 {
  margin-bottom: 7px;
}

.bottom10 {
  margin-bottom: 10px;
}

.bottom15 {
  margin-bottom: 15px;
}

.bottom17 {
  margin-bottom: 17px;
}

.bottom20 {
  margin-bottom: 20px;
}

.bottom30 {
  margin-bottom: 30px;
}

.bottom48 {
  margin-bottom: 48px;
}

.bottom50 {
  margin-bottom: 50px;
}

.left5 {
  margin-left: 5px;
}

.left7 {
  margin-left: 7px;
}

.left10 {
  margin-left: 10px;
}

.left15 {
  margin-left: 15px;
}

.left17 {
  margin-left: 17px;
}

.left20 {
  margin-left: 20px;
}

.left24 {
  margin-left: 24px;
}

.left25 {
  margin-left: 25px;
}

.left30 {
  margin-left: 30px;
}

.left50 {
  margin-left: 50px;
}

.left55 {
  margin-left: 55px;
}

.left60 {
  margin-left: 60px;
}

.right5 {
  margin-left: -5px;
}

.right7 {
  margin-left: -7px;
}

.right10 {
  margin-left: -10px;
}

.right15 {
  margin-left: -15px;
}

.right17 {
  margin-left: -17px;
}

.right20 {
  margin-left: -20px;
}

.right30 {
  margin-left: -30px;
}

.tr-padding-2 {
  padding: 2rem;
}

.tr-pb-2 {
  padding-bottom: 2rem;
}

.tr-pt-2 {
  padding-top: 2rem;
}

.tr-py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tr-px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tr-pe-2 {
  padding-right: 2rem;
}

.tr-ps-2 {
  padding-left: 2rem;
}

.noto-sans {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: bold;
}

.btn-primary {
  background-color: var(--tr-btn-primary-color) !important;
  border-radius: 6px !important;
}

.btn-outline-primary {
  border: 1px solid var(--tr-btn-primary-outline-border-color);
  border-radius: 6px;
  color: var(--tr-btn-primary-outline-color);
}

.btn-outline-primary:hover {
  background: #046e78;
  color: #fff;
}

.font-16 {
  font-size: 16px;
}

.trMarginAuto {
  margin: auto !important;
}

.borderRed {
  border: solid red 1px !important;
}

.borderYellow {
  border: solid yellow 1px !important;
}

.borderBlue {
  border: solid Blue 1px !important;
}

.borderBlack {
  border: solid Black 1px !important;
}

.displayTable {
  display: table;
}

.displayTableCell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.tr-mt-header {
  margin-top: var(--header-height);
}

.error-message {
  color: #fa0000;
  font-size: 0.75rem;
}

.w-100 {
  width: 100%;
  max-width: 100vw;
}

.cursor-pointer {
  cursor: pointer;
}

#tr-sidepanel-col {
  transition: all 0.3s linear;
  opacity: 1;
}

.tr-hide-side-panel #tr-sidepanel-col {
  margin-left: var(--sidepanel-negative-width) !important;
  transition: all 0.3s linear;
  opacity: 0;
}

.tr-error-message {
  position: absolute;
  top: 3.3rem;
}

.tr-flex-gap-1 {
  gap: 1rem;
}

.tr-flex-gap-15 {
  gap: 1.5rem;
}

.tr-flex-gap-2 {
  gap: 2rem;
}

.tr-flex-gap-row-2 {
  row-gap: 2rem;
}

.tr-bd-6 {
  border-radius: 6px;
}

.tr-reg-popup-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
  color: #444444;
}

.tr-reg-popup-desc {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  color: #888888;
  margin-bottom: 2rem;
}

.tr-row-gap-2 {
  grid-row-gap: 2rem;
}

.tr-prevent-selection {
  user-select: none;
}

.tr-loading .tr-blur {
  mix-blend-mode: luminosity;
  opacity: 0.4;
}

.tr-bg-highlight {
  background:var(--primary-bg-color);
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}

.tr-btn:hover {
  transition: all 0.2s ease;
  background: var(--btn-hover-color) !important;
  border-color: transparent !important;
  color: #ffffff !important;
}

.tr-btn:disabled,
.tr-btn[disabled] {
  background-color: #e5e5e5 !important;
  color: #bbbbbb !important;
  cursor: no-drop !important;
}

.tr-navigate-link:hover img {
  transform: translateX(5px);
  transition: all 0.3s ease-in-out;
}

.tr-navigate-link:hover,
.tr-navigate-link:hover span {
  color: #00b5c7 !important;
}

.tr-font-14 {
  font-size: 0.875rem;
}

.tr-font-12 {
  font-size: 0.75rem;
}

.tr-font-16 {
  font-size: 1rem;
}

.tr-font-18 {
  font-size: 1.125rem;
}

.tr-img-sm {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0 50% 50%;
}

.tr-dummy-image-sm{
  min-width: 2.5rem;
  min-height: 2.5rem;
  background: #FD1A5A;
  border-radius: 0 50% 50%;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 600;
}

.tr-main-header-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--title-text-color);
}

.tr-sub-title-dark-text {
  font-weight: 600;
  line-height: 1rem;
  color: #444444;
}

.tr-dark-text-md {
  font-weight: 400;
  line-height: 1rem;
  color: #444444;
}

.tr-dark-text-lg {
  font-weight: 500;
  line-height: 1.5rem;
  color: #444444;
}

.tr-dark-text-bold {
  font-weight: 700;
  line-height: 1.5rem;
  color: #444444;
}

.tr-sub-title-lite-text {
  font-weight: 400;
  line-height: 1rem;
  color: #888888;
}

.tr-sub-title-lite-text-lg {
  font-weight: 500;
  line-height: 1rem;
  color: #888888;
}

.tr-hover:hover {
  background: var(--hover-color);
  cursor: pointer;
}

.tr-btn-primary-outline {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background: transparent;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #046e78;
  border: 1px solid rgba(4, 110, 120, 0.3);
}

.tr-btn-primary {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background: #046e78;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #ffffff;
  border: 1px solid transparent;
}

.tr-btn-outline-primary {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background: transparent;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #046e78;
  border: 1px solid #046e78;
}

.tr-btn-danger {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background: #D92D20;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #ffffff;
  border: 1px solid transparent;
}

.tr-spinner-sec {
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0px 2px 10px 4px #0000000a;
}

/*classes for dropdown hover*/

.tr-dropdown-options li {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.2rem;
    color: #444444;
    white-space: nowrap;
}

.tr-dropdown-options li:hover{
  transition: all 0.3s ease;
  border-left: 4px solid;
  border-radius: 4px;
}
.tr-dropdown-options li{
  border-left: 4px solid transparent;
}

.tr-dropdown-options .cancel:hover, .tr-dropdown-options .cancel-request:hover,.tr-dropdown-options .raise_complaint:hover, .tr-dropdown-options .reject:hover, .tr-dropdown-options .cancel_request:hover{
  color: #C8102E !important;
  background: rgba(200, 16, 46, 0.13);
}

.tr-dropdown-options .accept:hover, .tr-dropdown-options .complete:hover{
  color: #2E9400 !important;
  background: #E3F8E8;
}

.tr-dropdown-options .cancel:hover svg path, .tr-dropdown-options .reject:hover svg path, .tr-dropdown-options .raise_complaint:hover svg path{
  fill: #C8102E;
}

.tr-dropdown-options .accept:hover svg path, .tr-dropdown-options .complete:hover svg path{
  fill: #2E9400;
}

.tr-dropdown-options .cancel_request:hover svg path{
  fill: #C8102E;
}

.tr-dropdown-options .reschedule:hover svg path, .tr-dropdown-options .request_reschedule:hover svg path{
  fill: #015570;
}

.tr-dropdown-options .reschedule:hover, .tr-dropdown-options .request_reschedule:hover, .tr-dropdown-options .give_new_time:hover, .tr-dropdown-options .edit:hover{
  color: #015570;
  background: #E3F3F9;
}

.cal-week-view, .cal-week-view , .cal-week-view .cal-time-events, .cal-week-view .cal-day-column{
  border: none !important;
}

.cal-week-view .cal-hour-odd {
    background-color: transparent !important;
}

/* Base styles for the calendar */
.cal-day-columns .cal-day-column .cal-hour .cal-hour-segment {
    border: 2px solid #FFFFFF !important;
    background: #f9f9f9;
    border-radius: 2px;
}

/* Skeleton loader animation */
 .cal-day-columns .cal-day-column .cal-hour .slot-loading::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f9f9f9 25%, #e0e0e0 50%, #f9f9f9 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}


.cal-week-view .cal-time {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #444444;
}

.cal-time-events{
    padding-top: 0.8rem;
}

.cal-week-view .cal-header.cal-today {
    background-color: transparent !important;
}

.tr-book-lesson-window{
  background: #f9f9f9;
    opacity: 1;
  z-index: 999;
  top: 0;
    right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  border-radius: 20px 20px 0 0;
}
.tr-book-lesson-wrapper{
    width: inherit;
    height: inherit;
}

#bookLessonModal {
  border-radius: 20px 20px 0 0;
}

.tr-slot-close-icon{
  width: 15px;
  height: 15px;
  background: #FFFFFF;
  border-radius: 50%;
}

.show[data-tooltip] {
    position: relative;
    letter-spacing: 0.1rem;
}

.show[data-tooltip]::before,
.show[data-tooltip]::after {
    --tooltip-color: #333;
    --arrow-size: .5rem;
    --scale: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, var(--translate-y)) scale(var(--scale));
    transition: transform 100ms;
    transition-timing-function: linear;
}

.show[data-tooltip]:hover::before,
.show[data-tooltip]:hover::after {
    --scale: 1;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
}

.show[data-tooltip]::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    background-color: #00b5c7;
    color: white;
    padding: .5em;
    border-radius: .3em;
    width: max-content;
    max-width: 100%;
    text-align: center;
    font-size: 0.75rem;
    transform-origin: bottom center;
}

.show[data-tooltip]::after {
    --translate-y: calc(-1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: #00b5c7;
    transform-origin: top center;
}

.tr-striketrough{
  text-decoration: line-through;
  opacity: 0.7;
}

@media(max-width: 992px){
  .tr-padding-2 {
    padding: 1.5rem;
  }

  .tr-px-mb-0{
    padding-left: 0 !important;
    padding-right: 0 !important ;
  }

  .tr-pb-2 {
    padding-bottom: 1.5rem;
  }

  .tr-pt-2 {
    padding-top: 1.5rem;
  }

  .tr-py-2 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .tr-px-2 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .tr-pe-2 {
    padding-right: 1.5rem;
  }

  .tr-ps-2 {
    padding-left: 1.5rem;
  }
}

