@import "~bootstrap/dist/css/bootstrap.css";
@import "global-styles/design-system.css";
@import "global-styles/tr-custom-classes.css";
@import "global-styles/theme.css";

/* Dark mode */
:root {
    /*In app header*/
    --in-app-header-l: #FFFFFF;
    --in-app-header-d: #222222;
    --sidepanel-max-width: 12.688rem;
    --sidepanel-width: 12.688rem;
    --sidepanel-negative-width: -12.688rem;
    --side-nav-d: #2C2C2C;
    --side-panel: #272727;
    --header-height: 3.7rem;
    --font-size: 16px;
    font-size: var(--font-size);
    --line-border-color: #046E78;
    --hover-color: rgba(224, 247, 250, 0.28);
    --disabled-line-border: #ECECEC;
    --btn-hover-color: #00B5C7;

}

body {
    font-family: 'Noto Sans', sans-serif;
    background-color: #F9F9F9;
    --primary-bg-color: #FFFFFF;
    --secondary-bg-color: #FFFFFF;
    --primary-text-color: #444444;
    --secondary-text-color: #888888;
    --sidenav-bg-primary-color: #046E78;
    --title-text-color: #046E78;
    --in-app-header-background: #FFFFFF;
    --border-grey-color: #ddd;
    --tr-background-color: #F9F9F9;
    --tr-section-background-color: #F9F9F9;
    --tr-tutor-tile-bg-color: #FFFFFF;
    --tr-toaster-bg-color: #FFFFFF;
    --tr-tutor-tile-count-bg: #EBEBEB;
    --tr-tutor-tile-lang-level-color: #1C9209;
    --tr-tutor-tile-secondary-color: #888888;
    --tr-btn-primary-color: #046E78;
    --tr-btn-primary-outline-color: #046E78;
    --tr-btn-primary-outline-border-color: rgba(4, 110, 120, 0.3);
    --tr-arrow-down-icon-color: #046E78;
    --tr-primart-btn-border-color: rgba(4, 110, 120, 0.3);
    --tr-lite-grey-bg: #F1F1F1;
    --tr-progress-background: #E5E5E5;
    --dropdown-bg-color: #F8F8F8;

}

body.dark {
    background-color: #2C2C2C;
    --primary-bg-color: #1E1E1E;
    --secondary-bg-color: #2C2C2C;
    --dropdown-bg-color: #2C2C2C;
    --primary-text-color: #FFFFFF;
    --secondary-text-color: #FFFFFF;
    --title-text-color: #FFFFFF;
    --sidenav-bg-primary-color: #2C2C2C;
    --in-app-header-background: #222222;
    --tr-toaster-bg-color: #2D2D2D;
    --border-grey-color: #2C2C2C;
    --tr-background-color: #2C2C2C;
    --tr-section-background-color: #1E1E1E;
    --tr-tutor-tile-bg-color: #272727;
    --tr-tutor-tile-count-bg: #444444;
    --tr-tutor-tile-lang-level-color: #00DA57;
    --tr-tutor-tile-secondary-color: #C6C6C6;
    --tr-btn-primary-color: #00B5C7;
    --tr-btn-primary-outline-color: #FFFFFF;
    --tr-btn-primary-outline-border-color: rgba(255, 255, 255, 0.3);
    --tr-arrow-down-icon-color: #FFFFFF;
    --tr-primart-btn-border-color: #FFFFFF;
    --tr-lite-grey-bg: #272727;
    --tr-progress-background: #464646;
}

.form-control:focus, select:focus, input:focus, textarea:focus {
    outline: none !important;
    border: 1px solid #00B5C7 !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 181, 199, 0.50) !important;
    color: #000;
}

input:-internal-autofill-selected {
    background-color: none !important;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    height: 3rem !important;
    background: #FFFFFF !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 4px !important;
    margin-left: 0 !important;
    width: 100% !important;
}

.iti--allow-dropdown input, 
.iti--allow-dropdown input[type=tel]:focus{
   border: 1px solid #00B5C7 !important; 
}

.country-dropdown {
    max-width: 26vw !important;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    background: #046E78;
    color: #fff;
}


.tr-hide-side-panel .mx-lg-100 {
    max-width: 100% !important;
}

.mx-lg-100{
   transition: all 0.3s linear;
}

ngx-intl-tel-input {
    width: inherit !important;
}

.iti {
    width: inherit !important;
}

.ngx-slider .ngx-slider-pointer {
    background: #FFFFFF !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
    top: -1.438rem !important;
    border: 1px solid #046E78 !important;
}

.ngx-slider .ngx-slider-pointer:after {
    content: '';
    width: 0px !important;
    height: 0px !important;
    position: absolute;
    top: 0px !important;
    left: 0px !important;
    border-radius: 4px !important;
    background: transparent !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
    left: 0;
    box-sizing: border-box;
    margin-top: -2.188rem !important;
    padding-top: 1rem !important;
}

.ngx-slider .ngx-slider-selection {
    z-index: 2;
    background: #046E78 !important;
    border-radius: 2px;
}

.ngx-slider .ngx-slider-bubble {
    top: 0 !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: #444444 !important;
}

.ngx-ic-cropper .ngx-ic-move {
    border: 1px dashed #FFFFFF !important;
    box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 4%) !important;
    border-radius: 0px 320px 320px 320px !important;
}

.ngx-ic-cropper {
    color: transparent !important;
}

.ngx-ic-cropper .ngx-ic-resize .ngx-ic-square {
    border: 1px solid #000 !important;
    background: #FFFFFF !important;
}

.slick-prev,
.slick-next {
    z-index: 100;
    position: absolute;
    top: 105%;
    font-size: 15px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-prev {
    left: 0.5rem;
    background: #E5E5E5;
    border-radius: 6px;
}

.slick-next {
    right: 0.5rem;
}

.slick-prev:before {
    content: url('assets/icons/arrow-left.svg');
    position: absolute;
    top: -2px;
    left: 5px;
}

.slick-next:before {
    content: url('assets/icons/arrow-right.svg');
    position: absolute;
    top: -2px;
    left: 5px;
}

.slick-dots li.slick-active button:before {
    color: #046E78;
    opacity: 0.75;
}

.slick-dots li button:before {
    color: #046E78;
}

/*add datatable search bar icon*/
div.dataTables_wrapper div.dataTables_filter input {
    background-image: url('/assets/icons/search.svg') !important;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: right 10px center !important;
    border: 1px solid #DDDDDD !important;
    height: 2rem !important;
    border-radius: 6px;
    padding: 0.5rem 1rem !important;
    margin-bottom: 1.125rem !important;
    margin-left: 1.5rem !important;
    font-weight: 500;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: #888888;
}

.dataTables_wrapper .dataTables_length label {
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #444444
}

div.dataTables_wrapper div.dataTables_filter input:focus {
    background-image: none !important;
}

div.dataTables_wrapper div.dataTables_length select {
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    margin-left: 1rem;
    padding: 0.5rem;
    line-height: 1;
    height: 32px;
    appearance: none;
    line-height: 1;
    background-repeat: no-repeat, repeat;
    background-position: right 1rem top 50%, 0 0;
    background-image: url(assets/icons/arrow-down-lite.svg);
    padding: 0.4rem 1rem !important;
    width: 75px !important;
}

.dataTables_info {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #444444;
}

.dataTables_info span {
    font-weight: 500;
    font-size: 10px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 6px;
    background: #E5E5E5;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
}

.active>.page-link {
    background: #046E78;
    border-radius: 6px;
    border-color: #046E78;
    color: #FFFFFF !important;
}

.page-item:not(:first-child) .page-link {
    color: #046E78;
}

.page-link {
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 6px !important;
    border: 1px solid #E5E5E5 !important;
    padding: 4px 8px !important;
}

.page-link:hover {
    background: #046E78 !important;
    color: #FFFFFF !important;
}

.page-link img {
    width: 0.625rem !important;
    height: 0.625rem !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    margin: 0;
    padding: 0 !important;
    max-width: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 !important;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0 !important;
}

.dataTables_length {
    margin-right: 1rem !important;
}

.dataTables_info {
    margin-right: 1.5rem !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-left: 1.5rem !important;
    gap: 0.5rem !important;
}


.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid transparent !important;
    background-color: #046E78 !important;
    background: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    box-shadow: none !important;
}


table.dataTable>tbody>tr.child ul.dtr-details>li {
    display: flex;
}

table.dataTable>tbody>tr.child span.dtr-title {
    display: inline-block;
    min-width: 30% !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    color: #888888 !important;

}

table.dataTable>tbody>tr.child span.dtr-data {
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    color: #444444 !important;

}

table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-bottom: 1px solid transparent !important;
}

table.dataTable>tbody>tr.child ul.dtr-details {
    width: 100%;
    padding: 1rem 0 !important;
}

table.dataTable.dtr-column>tbody>tr>td.dtr-control:before {
    top: 50%;
    left: 80% !important;
    height: 0.75rem !important;
    width: 0.75rem !important;
    margin-top: -0.5em;
    margin-left: -0.5em;
    display: block;
    position: absolute;
    color: white;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "Noto Sans", Courier, monospace;
    line-height: 1em;
    content: " " !important;
    background-color: transparent !important;
    background-image: url('assets/icons/arrow-down.svg') !important;
    background-repeat: no-repeat !important;
}

tbody .dt-hasChild,
tbody .child {
    background: #FAFEFE !important;
}

table.dataTable.dtr-column>tbody>tr.parent td.dtr-control:before {
    background-image: url('assets/icons/arrow_forward_ios.svg') !important;
}

@media(max-width: 567px) {
    table.dataTable>tbody>tr.child span.dtr-title {
        min-width: 50% !important;
    }
}

@media(min-width: 1200px) {
    .mx-lg-100 {
        max-width: calc(100% - var(--sidepanel-width));
        margin-left: auto;
    }

    #tr-main-outlet-div{
        margin-top: var(--header-height);
    }
}

@media(max-width: 1200px){
  .mx-lg-100{
      margin-top: calc(var(--header-height) + 5.5rem);
      padding-bottom: 4rem !important;
  }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.tr-flex-item {
  flex: 1;
  min-width: 100%;
  box-sizing: border-box;
}

@media (min-width: 1300px) {
  .tr-flex-item {
    min-width: calc(33.333% - 1.5rem);
  }
  .tr-flex-item{
    max-width:33%;
  }
}


@media (min-width: 772px) and (max-width: 1300px){
  .tr-flex-item {
    min-width: calc(50% - 1.5rem);
  }
}


@media(max-width: 1200px){
  .cal-week-view .cal-time-label-column {
      position: -webkit-sticky !important; 
      position: sticky !important;
      left: 0 !important;
      background: white !important;
      z-index: 10 !important;
      box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
      margin-top: 30px !important;
  }
  .cal-week-view .cal-time-events .cal-day-columns {
      margin-top: 30px !important;
  }
  .cal-week-view .cal-day-headers{
      position: absolute !important;
      z-index: 11 !important;
      background-color: #fff !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      width: 100% !important;
      top: 0;
      transition: transform 0.3s ease;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.8);
  }
}


