select {
    appearance: none !important;
    background: white !important;
    background-image: url("/assets/icons/arrow-down-lite.svg") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.5rem center !important;
    border-radius: 0.25rem !important;
    color: black !important;
    cursor: pointer;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    font-size-adjust: 0.5;
}

select:invalid {
    color: gray !important;
}

select[disabled] {
    color: gray !important;
}


select option {
    color: black !important;
}